import axios from 'axios'
import qs from 'query-string'
import storage, { AUTH_TOKEN, AUTH_ROLE } from '@/storage'
import router from '@/router'
import { app } from '@/main'
import { getHostnameInfo } from '@/tools/config'

const setBaseUrl = () => {
  const hostnameInfo = getHostnameInfo()
  return hostnameInfo.api_url
}

// const baseURL = process.env.API_URL;
const baseURL = setBaseUrl()

const CancelToken = axios.CancelToken
let cancel
const unauthorized_response_status_code = [401, 403]

// 基本的 api
const baseRequest = axios.create({ baseURL: `${baseURL}` })
const authRequest = axios.create({ baseURL: `${baseURL}` })
authRequest.interceptors.request.use((config) => {
  config.headers.authToken = storage.getItem(AUTH_TOKEN)
  config.headers.Authorization = `Bearer ${storage.getItem(AUTH_TOKEN)}`
  config.headers.role = storage.getItem(AUTH_ROLE)
  return config
})

authRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response = {} } = error
    // if (response.status === 401) {
    //   return router.push({ name: 'ControllerPanel' });
    // }
    if (unauthorized_response_status_code.includes(response.status)) {
      // if (process.env.NODE_ENV === 'development') {
      //   return
      // }
      app.$store.dispatch('auth/logout')
      if (router.name !== 'Login') {
        router.push({ name: 'Login' })
      }
    }
    return Promise.reject({ error, response })
  }
)
const authRequestWithFile = axios.create({ baseURL: `${baseURL}` })
authRequestWithFile.interceptors.request.use((config) => {
  config.headers.authToken = storage.getItem(AUTH_TOKEN)
  config.headers['Content-Type'] = 'multipart/form-data'
  return config
})

export const hello = () => baseRequest.get('/')

// manage
export const getPlayerList = (form) => authRequest.post('manage/player_list', form)
export const getBetLog = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/betlog', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getPlayerCredit = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/player_credit', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getGameResult = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/game_result', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getTransfer = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/transfer', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getTransferLog = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/transfer_log', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getMonthlyProfit = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/monthly_profit', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getDailyBettingStatistic = (form) => {
  if (cancel) {
    cancel()
  }
  return authRequest.post('manage/daily_betting_statistic', form, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
}
export const getAgents = (form) => authRequest.post('manage/agents', form)
export const getOrders = (form) => authRequest.post('manage/orders', form)
// export const getArenaModerator = () => authRequest.get('manage/moderator');
export const getMerchantRoles = ({ form }) => authRequest.post('manage/roles_list', form)
export const getMerchantAdmin = ({ form }) => authRequest.post('manage/merchant_admin_list', form)
export const getMerchantAllRoles = () => authRequest.get('manage/merchant_all_roles')
export const getMerchantRole = () => authRequest.get('manage/get_merchant_role')
export const getClientTheme = () => authRequest.get('manage/get_client_theme')
export const getClientCurrency = () => authRequest.get('manage/get_client_currency')
export const getMerchantCurrency = () => authRequest.get('manage/get_merchant_currency')
export const getMasterByCurrency = (form) => authRequest.post('manage/get_master_by_currency', form)
export const getMasterByMerchant = () => authRequest.get('manage/get_master_by_merchant')
export const getAgentByMerchant = () => authRequest.get('manage/get_agent_by_merchant')
export const getAgentByCurrency = (form) => authRequest.post('manage/get_agent_by_currency', form)
export const getAgentByMaster = (form) => authRequest.post('manage/get_agent_by_master', form)
export const getDailyAgentByMaster = (form) => authRequest.post('manage/get_daily_agent_by_master', form)
export const getAgentAllRoles = () => authRequest.get('manage/get_agent_authoritys')
export const getLanguages = () => authRequest.get('manage/get_languages')
export const getCurrentUserLanguagesId = () => authRequest.get('manage/get_current_user_languages_id')
export const getUserLanguagesById = ({ id }) => authRequest.get(`manage/get_user_languages_by_id/${id}`)
export const getCurrentUserLanguagesList = () => authRequest.get('manage/get_current_user_languages_list')
export const getUserLanguagesList = ({ id }) => authRequest.get(`manage/get_user_languages_list/${id}`)
export const getMasterAgentLink = (form) => authRequest.post('manage/master_agent_link', form)
export const getLinkMasterByCurrency = (form) => authRequest.post('manage/get_link_master_by_currency', form)
export const exportDailyBettingStatistic = (form) =>
  authRequest.post('manage/export_daily_betting_statistic', form, { responseType: 'blob' })
export const getPrepaidBaseMode = (form) => authRequest.post('manage/get_prepaid_base_mode', form)

export const getDashboardCard = (form) => authRequest.post('manage/get_dashboard_card', form)
export const getManageDescendantList = () => authRequest.get('manage/get_manage_descendant_list')
export const getArenaList = (form) => authRequest.post('manage/get_arena_list', form)
export const getManageIpList = (form) => authRequest.post('manage/get_manage_ip_list', form)

// Exchange Rate
export const getExchangeRateList = () => authRequest.get('manage/exchange_rates')
export const updateCurrencyRates = () => authRequest.post('manage/exchange_rates/update_currency_rates')
export const updateExchangeTbdConfirmRate = (form) => authRequest.patch(`manage/exchange_rates/${form.id}`, form)
export const approveExchangeRate = (form) => authRequest.post('manage/exchange_rates/approve_exchange_rate', form)
export const getExchangeRateLog = (form) =>
  authRequest.get(`manage/get_exchange_rate_log/${form.id}?page_size=${form.page_size}&page=${form.page}`)

// Transfer
export const resendTransfer = (form) => authRequest.post('manage/resend_transfer', form)
export const transferMark = (form) => authRequest.post('manage/transfer_mark', form)

// Check Round
export const checkRoundOneSideBet = ({ id }) => authRequest.get(`manage/check_round_one_side_bet/${id}`)

// get Current Manage
export const getCurrentManageProfile = (page) => authRequest.get(`manage/get_current_manage_profile?page=${page}`)
export const getCurrentManageRate = () => authRequest.get('manage/get_current_manage_rate')

// auth
export const authLogin = (form) => baseRequest.post('account/admin/login', form)
export const getOtpIsOpen = ({ subdomain }) => baseRequest.get(`manage/get_otp_is_open/${subdomain}`)
export const agentCreate = (form) => authRequest.post('merchant/agents', form)
export const updatePassword = (form) => authRequest.patch('account/admin/password', form)
export const generate_agent = (form) => authRequest.post('manage/generate_agent', form)
export const refresh_agent_cache = (form) => authRequest.post('manage/refresh_agent_cache', form)
export const show_agent_cache_list = (form) => authRequest.get('manage/show_agent_cache_list', form)

export const updateLanguage = (form) => authRequest.post('manage/agents/update_language', form)
export const updateDefaultLanguage = (form) => authRequest.post('manage/agents/update_default_language', form)

// control panel
export const getPoolTodayfightHistory = ({ main_pool_id, result, need_arena_info }) =>
  authRequest.get(
    `merchant/pools/today_fight_history?main_pool_id=${main_pool_id}&result=${result}&need_arena_info=${need_arena_info}`
  ) // 回應 {"fight_history":[]}
export const setPoolDecisionOnHold = (form) => authRequest.post(`merchant/pools/hold`, form) // form = { pool_id: 8 }
export const setPoolEnableBet = (form) => authRequest.post(`merchant/pools/close_bet`, form) // form = { pool_id: 8 }
export const setPoolMaskVideo = (form) => authRequest.post(`merchant/pools/mask_video`, form) // form = { pool_id: 8 }
export const setPoolCloseArena = (form) => authRequest.post(`merchant/pools/arena_close`, form) // form = { pool_id: 8 }
export const setPoolOpenArena = (form) => authRequest.post(`merchant/pools`, form) // form = { fight_no: 1, road_map_id: 8965, fight_no: 1 }
export const setPoolOpenBet = (form) => authRequest.post(`merchant/pools/open_bet`, { open: 1, ...form }) // form = { open: 1, pool_id: 8 }
export const setPoolLastCall = (form) => authRequest.post(`merchant/pools/last_call`, form) // form = { pool_id: 8 }
export const setPoolCloseBet = (form) => authRequest.post(`merchant/pools/open_bet`, { open: 0, ...form }) // form = { open: 0, pool_id: 8 }
export const setPoolDeclareWinner = (form) => authRequest.post(`merchant/pools/close`, form) // form = { winner: "wala", pool_id: 8 }
export const setPoolReloadWinner = (form) => authRequest.post(`merchant/pools/reload`, form) // form = { winner: "meron", pool_id: 8 }
export const setPoolLock = (form) => authRequest.post(`merchant/pools/lock`, form) // form = { pool_id: 8 }
export const modifyFightId = (form) => authRequest.post('merchant/pools/update_fight_id', form)

// 先留著，避免current arena的api有問題
export const fightClose = (form) => authRequest.post('merchant/rounds/close', form)
export const fightReload = (form) => authRequest.post('merchant/rounds/reload', form)
export const fightLock = (form) => authRequest.post('merchant/rounds/lock', form)

// round moderator
export const fightAnnounce = (form) => authRequest.post('merchant/arenas/announce', form)
export const getTodayFightHistory = (form) =>
  authRequest.get(`merchant/arenas/today_fight_history?merchant_provider_id=${form.merchant_provider_id}`)
export const getDecisionOnHold = (form) => authRequest.post('merchant/arenas/hold', form)
export const getCloseBet = (form) => authRequest.post('merchant/arenas/close_bet', form)
export const getMaskVideo = (form) => authRequest.post('merchant/arenas/mask_video', form)
export const arenaClose = (form) => authRequest.post('merchant/arenas/close', form)
export const getMonitorVideo = (form) => authRequest.post('merchant/player/monitor_login', form)
export const getTodayMainPools = () => authRequest.get(`merchant/arenas/today_main_pool_list`)
export const getTodayProviders = (main_pool_id) =>
  authRequest.get(`merchant/arenas/today_provider_list?main_pool_id=${main_pool_id}`)
export const getModeratorByProvider = (provider_id) =>
  authRequest.get(`manage/moderator_by_provider?provider_id=${provider_id}`)
export const updateProviderUrl = (form) => authRequest.post(`merchant/arenas/update_provider_url`, form)
export const getTodayRoundByProvider = (form) =>
  authRequest.get(`merchant/arenas/today_round_by_provider?provider_id=${form.provider_id}&result=${form.result}`)
export const postRetryRoundLock = (form) => authRequest.post(`merchant/pools/lock_round`, form)
export const postRoundCancel = (form) => authRequest.post(`merchant/pools/cancel_round`, form)

// current arena
export const getTodayFightHistoryCount = (form) =>
  authRequest.get(
    `merchant/arenas/today_fight_history?merchant_provider_id=${form.merchant_provider_id}&result=${form.result}`
  )

// arena moderator
export const getArenaModerator = () => authRequest.get('manage/moderator')

// =-=-=-=-=-=-=-=-=-=-=-= Role =-=-=-=-=-=-=-=-=-=-=-=
export const createMerchantRole = (form) => authRequest.post('manage/roles', form)
export const updateMerchantRole = (form) => authRequest.patch(`manage/roles/${form.id}`, form)
export const deleteMerchantRole = ({ id }) => authRequest.delete(`manage/roles/${id}`)

export const createSubMerchant = (form) => authRequest.post('manage/managers', form)
export const updateSubMerchant = (form) => authRequest.patch(`manage/managers/${form.id}`, form)
export const deleteSubMerchant = ({ id }) => authRequest.delete(`manage/managers/${id}`)

export const getAgentManagerList = () => authRequest.get('manage/agent_managers')
export const createAgentManager = (form) => authRequest.post('manage/agent_managers', form)
export const updateAgentManager = (form) => authRequest.patch(`manage/agent_managers/${form.id}`, form)
export const deleteAgentManager = ({ id }) => authRequest.delete(`manage/agent_managers/${id}`)

export const createManager = (form) => authRequest.post('manage/create_merchant', form)
// =-=-=-=-=-=-=-=-=-=-=-= Role =-=-=-=-=-=-=-=-=-=-=-=

// Page Menu Default
export const getDefaultPermissions = () => authRequest.get('manage/get_default_permissions')
export const getMenuPermissions = () => authRequest.get('manage/menu_permissions')
export const getPagePermissions = (page) => authRequest.get(`manage/page_permissions?page=${page}`)

// Agent
export const getAgentMemberCount = () => authRequest.get('manage/agents/get_agent_member_count')
export const getAgentProfile = () => authRequest.get('manage/agents/agent_profile')
export const updateAgentProfile = (form) => authRequest.patch(`manage/agents/${form.id}`, form)
export const getAgentProfileFindById = ({ id }) => authRequest.get(`manage/agents/${id}`)
export const updateAgentBetLimit = (form) => authRequest.patch(`manage/agents/${form.id}/update_bet_limit`, form)
export const updatePlayerBetLimit = (form) => authRequest.patch(`manage/players/${form.id}/update_bet_limit`, form)
export const getBetLimit = ({ id }) => authRequest.get(`manage/agents/${id}/get_bet_limit`)
export const updateQuotaById = ({ id, form }) => authRequest.put(`manage/agents/${id}/update_third_party_quota`, form)
export const updatePrepaidFundLimit = (form) =>
  authRequest.patch(`manage/agents/${form.id}/update_prepaid_fund_limit`, form)

// Admin Use
export const getAdminInsideMerchant = () => authRequest.get('manage/get_admin_inside_merchant')
export const getMerchantCreateRoleList = ({ id, form }) =>
  authRequest.post(`manage/get_merchant_create_role_list/${id}`, form)
export const getAdminMerchantAdmin = ({ id, form }) => authRequest.post(`manage/admin_merchant_admin/${id}`, form)
// IP List
export const createIpList = (form) => authRequest.post('manage/admins/create_ip_list', form)
export const updateIpList = (form) => authRequest.post('manage/admins/update_ip_list', form)
export const deleteIpList = (form) => authRequest.post(`manage/admins/delete_ip_list`, form)

export const createMerchant = (form) => authRequest.post(`manage/admins/create_merchant`, form)
export const getProviders = () => authRequest.get(`manage/admins/get_providers`)
export const getAgentVideoSource = () => authRequest.get(`manage/agent_video_source`)
export const getProviderList = () => authRequest.get(`manage/provider_list`)
// Admin
export const updateProviders = (form) => authRequest.post(`manage/admins/update_providers`, form)
export const createProviders = (form) => authRequest.post(`manage/admins/create_providers`, form)
export const getMerchantList = () => authRequest.get(`manage/merchant_list`)

// Agent
export const updataVideoSource = (form) => authRequest.post(`manage/agents/updata_video_source`, form)

// Player
export const playerLogout = (form) => authRequest.post(`player/player/logout`, form)
export const updateProfiles = (form) => authRequest.patch(`player/player/update_language`, form)

// image
export const getOriginImage = (form) =>
  authRequest.get(
    `merchant/images/get_image_origin_url?source_id=${form.source_id}&name=${form.name}&source_type=${form.source_type}`
  )
export const getImage = (form) =>
  authRequest.get(
    `merchant/images/get_image_url?source_id=${form.source_id}&name=${form.name}&source_type=${form.source_type}`
  )
export const postImage = ({ form, id }) => authRequest.patch(`merchant/images/${id}`, form)
export const createImage = (form) => authRequest.post(`merchant/images`, form)
export const deleteImage = ({ id }) => authRequest.delete(`merchant/images/${id}`)

//malay odds setting
export const getMalayOddsList = ({ form }) => {
  const stringifyForm = qs.stringify(form)
  return authRequest.get(`manage/odds_profile?${stringifyForm}`)
}
export const getMalayOddsLogById = ({ id, form }) => {
  const stringifyForm = qs.stringify(form)
  return authRequest.get(`manage/odds_profile/${id}/odds_profile_log/?${stringifyForm}`)
}
export const createMalayOdds = ({ form }) => authRequest.post('manage/odds_profile', form)
export const updateMalayOdds = ({ form, id }) => authRequest.patch(`manage/odds_profile/${id}`, form)
export const deleteMalayOdds = ({ id }) => authRequest.delete(`manage/odds_profile/${id}`)

//malay pool setting
export const getPoolList = (form) => {
  const stringifyForm = qs.stringify(form)
  return authRequest.get(`manage/pool_schedule?${stringifyForm}`)
}
export const getPoolSettingById = ({ id }) => authRequest.get(`manage/pool_schedule/${id}`)
export const createPoolSetting = ({ form }) => authRequest.post('manage/pool_schedule', form)
export const updatePoolSetting = ({ form, id }) => authRequest.patch(`manage/pool_schedule/${id}`, form)
export const deletePoolSetting = ({ id }) => authRequest.delete(`manage/pool_schedule/${id}`)
export const getPoolChangeLogById = ({ id }) => authRequest.get(`manage/pool_schedule/logs?merchant_provider_id=${id}`)
// risk
export const getRiskTypes = () => authRequest.get(`manage/risk_types`)

// agent credit points
export const getAgentPointsRecord = (form) => {
  const stringifyForm = qs.stringify(form)
  return authRequest.get(`/prepaid_funds/order_company_log?${stringifyForm}`)
}
export const getPrepaidFundsAgent = (id) => authRequest.get(`prepaid_funds/agent_list?${id}`)
export const getPrepaidFunds = (form) => authRequest.get(`prepaid_funds?${form}`)
export const putPrepaidFundsPointsModify = ({ form, id }) => authRequest.put(`prepaid_funds/${id}`, form)
export const getPrepaidFundsUpdateLog = (form) => authRequest.get(`prepaid_funds/update_log?${form}`)
